<template>
  <section class="hero is-fullheight">
    <div class="hero-body is-align-items-flex-start">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title_view">
              Dashboard
            </h1>
          </div>
          <div class="column is-3-desktop is-6-tablet" v-for="item in dashboardData" :key="item.id">
            <CardInformationTraffic
              :title="item.title"
              :total="item.total"
              :icon="item.icon"
              :color="item.color"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DashboardMain',
  components: {
    CardInformationTraffic: () => import('@/components/Dashboard/CardInformationTraffic.vue')
  },
  data () {
    return {
      dashboardData: [
        {
          id: 1,
          title: 'Servicios',
          total: 0,
          icon: 'briefcase-sharp',
          color: '#1b8e42'
        },
        {
          id: 2,
          title: 'Productos',
          total: 0,
          icon: 'briefcase-sharp',
          color: '#00add8'
        },
        {
          id: 3,
          title: 'Usuarios',
          total: 0,
          icon: 'person-sharp',
          color: '#f49e70'
        },
        {
          id: 4,
          title: 'Transacciones',
          total: 0,
          icon: 'repeat-sharp',
          color: '#f57f84'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['getDashboard']),
    async getDash () {
      const resp = await this.getDashboard()
      if (resp.success) {
        this.dashboardData[0].total = resp.payload.products
        this.dashboardData[1].total = resp.payload.services
        this.dashboardData[2].total = resp.payload.users
        this.dashboardData[3].total = resp.payload.transactions
      }
    }
  },
  beforeMount () {
    this.getDash()
  }
}
</script>

<style lang="scss" scoped>

</style>
